<script>
import sanity from "../client";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const query = `*[_type == "consultingSection"] {
  _id,
mainTitle,
mainText,
readMore,
contactUs,
contactPersonName,
contactPersonPosition,
contactPersonEmail,
contactPersonPhoneNumber,
consultingSections,
consultingSections[]{
  leftTitle,
  leftText,
  rightTitle,
  rightText,
  "middleImage": middleImage.asset->url
},
  "supplyChain": supplyChain.asset->url,
"mobileConsulting": mobileConsulting.asset->url,
}`;
export default {
  name: "Consulting",
  data() {
    return {
      loading: true,
      query: [],
      show: false,
    };
  },

  mounted() {
    this.fetchConsulting();

    // function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
    //   var mql = window.matchMedia(mediaQuery);
    //   mql.addListener(function (e) {
    //     return layoutChangedCallback(e.matches);
    //   });
    //   layoutChangedCallback(mql.matches);
    // }
  },
  methods: {
    fetchConsulting() {
      this.loading = true;
      sanity
        .fetch(query)
        .then(
          (query) => {
            this.loading = false;
            this.query = query;
          },
          (error) => {
            this.error = error;
          }
        )
        .then(() => {
          this.installMediaQueryWatcher(
            "(min-width: 1024px)",
            function (matches) {
              if (matches) {
                let SupplyChainTl = gsap.timeline({
                  scrollTrigger: {
                    trigger: "#supply-chain-separator",
                    start: "top center ",

                    end: "bottom center",
                    toggleActions: "restart play none reverse",
                  },
                });
                SupplyChainTl.fromTo(
                  "#supply-chain-separator",
                  {
                    opacity: 0,
                  },
                  {
                    opacity: 1,
                    duration: 1.5,
                  }
                )
                  .to(
                    "#intro-prop-consulting-1",
                    {
                      opacity: 1,
                      x: 100,
                      duration: 1,
                    },
                    "<"
                  )
                  .to(
                    "#intro-prop-consulting-2",
                    {
                      opacity: 1,
                      duration: 1.5,
                      x: -100,
                    },
                    "<"
                  );

                let rights = gsap.utils.toArray(".text .right");
                let lefts = gsap.utils.toArray(".text .left");
                let consultingImages = gsap.utils.toArray("#consulting .img");
                let rightBoxes = gsap.utils.toArray(".box.right.popbox");
                rights.forEach((right) => {
                  let tlRight = gsap.timeline({
                    scrollTrigger: {
                      trigger: right,

                      start: "top center",
                      end: "top center",
                      toggleActions: "restart play none reverse",
                    },
                  });
                  tlRight.fromTo(
                    right,
                    {
                      paddingLeft: 100,
                      opacity: 0,
                    },
                    {
                      duration: 1,
                      paddingLeft: 25,
                      opacity: 1,
                    }
                  );
                });
                lefts.forEach((left) => {
                  let tlLeft = gsap.timeline({
                    scrollTrigger: {
                      trigger: left,

                      start: "top center",
                      end: "top center",
                      toggleActions: "restart play none reverse",
                    },
                  });
                  tlLeft.fromTo(
                    left,
                    {
                      paddingRight: 100,
                      opacity: 0,
                    },
                    {
                      duration: 1,
                      paddingRight: 25,
                      opacity: 1,
                    }
                  );
                });
                consultingImages.forEach((img) => {
                  let tlImg = gsap.timeline({
                    scrollTrigger: {
                      trigger: img,

                      start: "top center",
                      end: "top center",
                      toggleActions: "restart play none reverse",
                    },
                  });
                  tlImg.fromTo(
                    img,
                    {
                      opacity: 0,
                    },
                    {
                      duration: 0.5,
                      opacity: 1,
                    }
                  );
                });
                rightBoxes.forEach((i) => {
                  let tlRightBoxes = gsap.timeline({
                    scrollTrigger: {
                      trigger: i,
                      start: "top center ",
                      end: "bottom center",
                      toggleActions: "restart play none reverse",
                    },
                  });

                  tlRightBoxes.fromTo(
                    i,
                    {
                      right: -300,
                      autoAlpha: 0,
                    },

                    {
                      right: 40,
                      autoAlpha: 1,
                      duration: 2,
                    }
                  );
                });
              }
            }
          );
        });
    },

    showTrue() {
      this.show = !this.show;
    },
  },
};
</script>
<template>
  <section id="consulting" class="wrapper">
    <div id="consulting-background" class="clearfix">
      <div class="container">
        <img
          class="responsive"
          src="https://cdn.sanity.io/images/0mfvw1cs/production/52af94d97e6009f35c9a449fffe3db73c9634ceb-2432x700.png"
          alt="Dimex Intro"
        />
        <div id="consulting-content" class="body">
          <div
            id="intro-prop-consulting-1"
            class="props cloud scale scale-8"
            style="top: 260px; left: 179px"
          ></div>
          <div
            id="intro-prop-consulting-2"
            class="props cloud scale scale-6"
            style="top: 724px; left: 766px"
          ></div>
          <div
            id="consulting-box"
            class="box right popbox"
            v-for="i in query"
            :key="i._id"
          >
            <router-link :to="{ name: 'consulting' }" class="read-more-link">
              <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
              <p>
                {{ i.mainText[$i18n.locale] }}
              </p>
              <span class="min-link"
                ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                >{{ i.readMore[$i18n.locale] }}</span
              >
            </router-link>
            <a @click="showTrue" class="min-link contact"
              ><i class="fa-sharp fa-solid fa-lg fa-comment fa-read-more"></i
              >{{ i.contactUs[$i18n.locale] }}</a
            >
          </div>
          <div id="supply-chain-separator">
            <img
              class="responsive"
              src="https://cdn.sanity.io/images/0mfvw1cs/production/d9cb4e5ba01934f745ceacc013b1269f7aadde15-406x249.png"
              alt="Suppy Chain"
            />
          </div>
          <Transition>
            <div
              v-if="show"
              class="popup large"
              id="consulting-contact"
              style="bottom: -60px; right: 30px; display: block"
            >
              <ul v-for="i in query" :key="i._id">
                <li class="arrow right">
                  <div class="pointer">
                    <i
                      class="fa-sharp fa-solid fa-caret-up fa-lg"
                      style="color: #082b65"
                    ></i>
                  </div>
                </li>

                <li class="header dark popup-flex">
                  <div>
                    <h6>{{ i.contactPersonName[$i18n.locale] }}</h6>
                    <p>{{ i.contactPersonPosition[$i18n.locale] }}</p>
                  </div>

                  <i
                    class="fa-sharp fa-solid fa-2x fa-comment"
                    style="color: white"
                  ></i>
                </li>
                <li class="email light popup-flex">
                  <i class="fa-solid fa-mobile fa-2x" style="color: white"></i>
                  <p>{{ i.contactPersonPhoneNumber }}</p>
                </li>
                <li class="header dark popup-flex">
                  <i
                    class="fa-solid fa-envelope fa-2x"
                    style="color: white"
                  ></i>
                  <p>{{ i.contactPersonEmail }}</p>
                </li>
              </ul>
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div id="supply-chain-wrapper" class="wrapper clearfix">
      <div id="supply-chain" class="container">
        <div class="body">
          <div v-for="i in query" :key="i.title">
            <div v-for="j in i.consultingSections" :key="j.title">
              <div class="bullet">
                <div class="text">
                  <div class="left">
                    <h2 v-if="$i18n.locale === 'en'">
                      YOU <br />
                      {{ j.leftTitle[$i18n.locale] }}
                    </h2>
                    <h2 v-if="$i18n.locale === 'bg'">
                      ВИЕ <br />
                      {{ j.leftTitle[$i18n.locale] }}
                    </h2>
                    <p>{{ j.leftText[$i18n.locale] }}</p>
                  </div>
                </div>
                <div class="img">
                  <img v-lazy="j.middleImage" alt="Consulting Services Image" />
                </div>
                <div class="text">
                  <div class="right">
                    <h2 v-if="$i18n.locale === 'en'">
                      WE <br />
                      {{ j.rightTitle[$i18n.locale] }}
                    </h2>
                    <h2 v-if="$i18n.locale === 'bg'">
                      НИЕ <br />
                      {{ j.rightTitle[$i18n.locale] }}
                    </h2>
                    <p>{{ j.rightText[$i18n.locale] }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="i in query" :key="i.title">
      <img class="responsive" v-lazy="i.mobileConsulting" />
    </div>
  </section>
</template>
