<script>
import sanity from "../client";

const query = `*[_type == "transportSection"] {
  _id,
mainTitle,
mainText,
readMore,
transportSections,
transportSections[]{
  "iconImage": iconImage.asset->url,
  title,
  text, 
  contactPersonName,
  contactPersonEmail,
  classes, 
  link, 
  showID, 
  dataID, 
  style
}, 
"mobileTransport": mobileTransport.asset->url
}`;
export default {
  name: "transport",
  data() {
    return {
      loading: true,
      query: [],
    };
  },

  created() {
    this.fetchTransport();
  },
  methods: {
    fetchTransport() {
      this.loading = true;
      sanity.fetch(query).then(
        (query) => {
          this.loading = false;
          this.query = query;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<template>
  <section id="transport" class="wrapper clearfix lazy">
    <div id="transport-background" class="clearfix lazy">
      <div id="transport-harbor" class="clearfix lazy">
        <div id="transport-content" class="container clearfix">
          <div class="body">
            <div
              id="intro-prop-transport-1"
              class="props airplane-right scale scale-6 lazy"
              style="top: -122px"
            ></div>
            <div
              id="intro-prop-transport-2"
              class="props cloud scale scale-8 lazy"
              style="top: -119px; left: 409px"
            ></div>
            <div
              id="intro-prop-transport-3"
              class="props cloud scale scale-4 lazy"
              style="top: -89px; left: 787px"
            ></div>

            <span v-for="i in query" :key="i._id">
              <div id="transport-box" class="box right popbox">
                <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
                <p>
                  {{ i.mainText[$i18n.locale] }}
                </p>
              </div>
              <span v-for="j in i.transportSections" :key="j.title">
                <div :class="j.classes">
                  <router-link :to="j.link" class="read-more-link">
                    <img v-lazy="j.iconImage" class="icon" alt="" />
                    <h2>{{ j.title[$i18n.locale] }}</h2>
                    <p>
                      {{ j.text[$i18n.locale] }}
                    </p>
                    <span class="min-link" style="margin-top: 8px"
                      ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                      >{{ i.readMore[$i18n.locale] }}</span
                    >
                  </router-link>
                </div>
              </span>
            </span>
          </div>
          <div id="truck-wrapper">
            <div id="truck"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="i in query" :key="i.title">
      <img class="responsive" v-lazy="i.mobileTransport" />
    </div>
  </section>
</template>
