<script>
import sanity from "../client";
import emailjs from "emailjs-com";
const contactUsQuery = `*[_type == "contactUs"] {
  _id,
mainTitle,
mainText,
phoneNumber,
"phoneIcon": phoneIcon.asset->url,
email,
"emailIcon": emailIcon.asset->url,
facebook,
"facebookIcon": facebookIcon.asset->url,
instagram,
"instagramIcon": instagramIcon.asset->url,
ourWarehousesTitle,
ourWarehouses[] {
  street,
  city,
  country
}, 
"warehousesIcon": warehousesIcon.asset->url,
successMessage, 
sendUsAMessageTitle, 
thankYouTitle, 
loadingGif
}`;
export default {
  name: "whoWeAre",
  data() {
    return {
      loading: true,
      contactUsQuery: [],
      name: "",
      email: "",
      message: "",
      phone: "",
      loadingForm: false,
      showSuccess: false,
    };
  },

  created() {
    this.fetchWhoWeAre();
  },
  methods: {
    fetchWhoWeAre() {
      this.loading = true;
      sanity.fetch(contactUsQuery).then(
        (contactUsQuery) => {
          this.loading = false;
          this.contactUsQuery = contactUsQuery;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
    sendEmail() {
      (this.loadingForm = true),
        emailjs
          .sendForm(
            "service_irourri",
            "template_5pt1f8u",
            this.$refs.form,
            "v7DjBmfP3qALtuTmF"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.text);
              this.name = "";
              this.email = "";
              this.phone = "";
              this.message = "";
              this.loadingForm = false;
              this.showSuccess = true;
            },
            (error) => {
              console.log("FAILED...", error.text);
            }
          );
    },
  },
};
</script>
<template>
  <section id="get-in-touch" class="wrapper clearfix">
    <div class="container">
      <div class="body">
        <div class="footer-top" v-for="i in contactUsQuery" :key="i._id">
          <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
          <p>
            {{ i.mainText[$i18n.locale] }}
          </p>
        </div>
        <div class="column left" v-for="i in contactUsQuery" :key="i._id">
          <div class="content">
            <div class="contact-info-box">
              <a :href="`tel:${i.phoneNumber[$i18n.locale]}`">
                <img v-lazy="i.phoneIcon" alt="Phone number icon" />
              </a>
              <a :href="`tel:${i.phoneNumber[$i18n.locale]}`">
                <p>{{ i.phoneNumber[$i18n.locale] }}</p>
              </a>
            </div>
            <div class="contact-info-box">
              <a :href="`mailto:${i.email}`">
                <img v-lazy="i.emailIcon" alt="Email icon" />
              </a>
              <a :href="`mailto:${i.email}`">{{ i.email }}</a>
            </div>
            <hr class="solid-divider" />
            <div class="contact-info-box-social">
              <a :href="'//' + i.facebook" target="_blank"
                ><img v-lazy="i.facebookIcon" alt="Facebook link icon"
              /></a>
              <a :href="'//' + i.instagram" target="_blank"
                ><img v-lazy="i.instagramIcon" alt="Instagram link icon"
              /></a>
            </div>
            <hr class="solid-divider" />
            <h2 class="our-warehouses-title">
              {{ i.ourWarehousesTitle[$i18n.locale] }}
            </h2>
            <div class="our-warehouses-wrapper">
              <div
                v-for="j in i.ourWarehouses"
                :key="j.street"
                class="our-warehouses-inner"
              >
                <img
                  v-lazy="i.warehousesIcon"
                  alt="Warehouses location icon"
                  style="width: 48px"
                />
                <div>
                  <p>{{ j.street[$i18n.locale] }}</p>
                  <p>{{ j.city[$i18n.locale] }}</p>
                  <p>{{ j.country[$i18n.locale] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column right">
          <div class="content">
            <div v-for="i in contactUsQuery" :key="i.title">
              <h2 class="title send-us-a-message" v-if="!showSuccess">
                {{ i.sendUsAMessageTitle[$i18n.locale] }}
              </h2>
              <h2 class="title send-us-a-message" v-if="showSuccess">
                {{ i.thankYouTitle[$i18n.locale] }}
              </h2>
            </div>

            <form
              id="message-form"
              ref="form"
              @submit.prevent="sendEmail"
              v-if="!showSuccess && !loadingForm && $i18n.locale === 'en'"
            >
              <label for="name" class="input">
                <input
                  id="name"
                  type="text"
                  name="name"
                  required=""
                  v-model="name"
                  placeholder="Your Name"
                />
              </label>
              <label for="email" class="input">
                <input
                  id="email"
                  type="email"
                  name="email"
                  required=""
                  v-model="email"
                  placeholder="E-mail"
                />
              </label>
              <label for="phone" class="input">
                <input
                  id="phone"
                  type="phone"
                  name="phone"
                  required=""
                  v-model="phone"
                  placeholder="Phone Number"
                />
              </label>

              <label for="message" class="input">
                <textarea
                  id="message"
                  name="message"
                  required=""
                  v-model="message"
                  placeholder="Your Message..."
                ></textarea>
              </label>

              <input type="submit" class="btn-submit" value="Send" />
            </form>
            <form
              id="message-form"
              ref="form"
              @submit.prevent="sendEmail"
              v-if="!showSuccess && !loadingForm && $i18n.locale === 'bg'"
            >
              <label for="name" class="input">
                <input
                  id="name"
                  type="text"
                  name="name"
                  required=""
                  v-model="name"
                  placeholder="Име"
                />
              </label>
              <label for="email" class="input">
                <input
                  id="email"
                  type="email"
                  name="email"
                  required=""
                  v-model="email"
                  placeholder="Имейл адрес"
                />
              </label>
              <label for="phone" class="input">
                <input
                  id="phone"
                  type="phone"
                  name="phone"
                  required=""
                  v-model="phone"
                  placeholder="Телефонен номер"
                />
              </label>

              <label for="message" class="input">
                <textarea
                  id="message"
                  name="message"
                  required=""
                  v-model="message"
                  placeholder="Съобщение..."
                ></textarea>
              </label>

              <input type="submit" class="btn-submit" value="Изпратете" />
            </form>

            <div v-for="i in contactUsQuery" :key="i.title">
              <div class="success" v-if="loadingForm">
                <img
                  src="https://cdn.sanity.io/images/0mfvw1cs/production/fda91cd37c872a9bb0a9ce21598699ddfe7cb624-200x200.gif"
                  alt="Loading Gif"
                />
              </div>
              <div class="success" v-if="showSuccess">
                {{ i.successMessage[$i18n.locale] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
