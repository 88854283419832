import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/Home.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("@/views/NotFound.vue"),
  },

  {
    path: "/consulting",
    name: "consulting",
    component: () => import("@/views/Consulting.vue"),
  },

  {
    path: "/customs",
    name: "customs",
    component: () => import("@/views/Customs.vue"),
  },
  {
    path: "/logistics",
    name: "logistics",
    redirect: "/logistics/intro",
    component: () => import("@/views/Logistics.vue"),
  },
  {
    path: "/logistics/intro",
    name: "logisticsIntro",
    component: () => import("@/views/logisticsPage/Intro.vue"),
  },
  {
    path: "/logistics/areas-of-expertise",
    name: "logisticsAOE",
    component: () => import("@/views/logisticsPage/AreasOfExpertise.vue"),
  },
  {
    path: "/logistics/in-house-logistics",
    name: "inHouseLogistics",
    component: () => import("@/views/logisticsPage/inHouseLogistics.vue"),
  },
  {
    path: "/logistics/reverse-logistics",
    name: "reverseLogistics",
    component: () => import("@/views/logisticsPage/reverseLogistics.vue"),
  },
  {
    path: "/distribution",
    name: "distribution",
    redirect: "/distribution/intro",
    component: () => import("@/views/Distribution.vue"),
  },
  {
    path: "/distribution/intro",
    name: "distributionIntro",
    component: () => import("@/views/distributionsPage/Intro.vue"),
  },
  {
    path: "/distribution/pod",
    name: "pod",
    component: () => import("@/views/distributionsPage/Pod.vue"),
  },
  {
    path: "/distribution/cargo-insurance",
    name: "cargo-insurance",
    component: () => import("@/views/distributionsPage/CargoInsurance.vue"),
  },
  {
    path: "/distribution/cash-and-cheque",
    name: "cash",
    component: () => import("@/views/distributionsPage/Cash.vue"),
  },
  {
    path: "/distribution/shipping-follow-up",
    name: "shipping",
    component: () => import("@/views/distributionsPage/Shipping.vue"),
  },
  {
    path: "/transport",
    name: "transport",
    redirect: "/transport/road-transport",
    component: () => import("@/views/Transport.vue"),
  },

  {
    path: "/transport/air-transport",
    name: "airTransport",
    component: () => import("@/views/transportPage/airTransport.vue"),
  },
  {
    path: "/transport/bulk-cargo",
    name: "bulkCargo",
    component: () => import("@/views/transportPage/bulkCargo.vue"),
  },
  {
    path: "/transport/ocean-transport",
    name: "oceanTransport",
    component: () => import("@/views/transportPage/oceanTransport.vue"),
  },
  {
    path: "/transport/road-transport",
    name: "roadTransport",
    component: () => import("@/views/transportPage/roadTransport.vue"),
  },
];

const router = createRouter({
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
  history: createWebHistory(),
  routes,
});
export default router;
