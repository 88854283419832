<script>
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "BackToTop",
  data() {
    return {
      isTop: false,
    };
  },
  methods: {
    scrollToTop() {
      gsap.to(window, {
        duration: 1.5,
        scrollTo: 0,
        ease: "power4.inOut",
      });
      // setTimeout(() => {
      //   document.querySelector("#box").classList.remove("stickit");
      // }, 2800);
    },
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 200) {
        that.isTop = true;
      } else {
        that.isTop = false;
      }
    });
  },
};
</script>
<template>
  <div @click="scrollToTop()" :class="['back-to-top-btn', { 'go-top': isTop }]">
    <i class="fa-solid fa-arrow-up"></i>
  </div>
</template>
