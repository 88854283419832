<script>
import sanity from "../client";
const query = `*[_type == "distributionSection"] {
  _id,
mainTitle,
mainText,
contactPersonName,
contactPersonPosition,
contactPersonPhone,
contactPersonEmail,
readMore,
contactUs,
distributionections[]{
  "iconImage": iconImage.asset->url,
  title,
  text, 
  classes, 
  link,
  },
"mobileDistribution": mobileDistribution.asset->url
}`;
export default {
  name: "Distribution",
  data() {
    return {
      loading: true,
      query: [],
      show: false,
    };
  },

  created() {
    this.fetchDistribution();
  },
  methods: {
    fetchDistribution() {
      this.loading = true;
      sanity.fetch(query).then(
        (query) => {
          this.loading = false;
          this.query = query;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
    showTrue() {
      this.show = !this.show;
    },
  },
};
</script>
<template>
  <section id="distribution" class="wrapper clearfix lazy">
    <div id="distribution-background" class="clearfix lazy">
      <div class="container clearfix">
        <div class="body" v-for="i in query" :key="i._id">
          <div
            id="props-distribution-1"
            class="props cloud scale scale-9 lazy"
            style="top: -195px; left: 681px"
          ></div>
          <div
            id="props-distribution-2"
            class="props cloud scale scale-7"
            style="top: 0; left: 154px"
          ></div>
          <div id="distribution-box" class="box right popbox">
            <router-link
              :to="{ name: 'distributionIntro' }"
              class="read-more-link"
              data-rel="#distribution"
            >
              <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
              <p>
                {{ i.mainText[$i18n.locale] }}
              </p>
              <span class="min-link"
                ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                >{{ i.readMore[$i18n.locale] }}</span
              >
            </router-link>
            <a @click="showTrue" class="min-link contact"
              ><i class="fa-sharp fa-solid fa-lg fa-comment fa-read-more"></i
              >{{ i.contactUs[$i18n.locale] }}</a
            >
          </div>
          <Transition>
            <div
              v-if="show"
              class="popup large"
              id="consulting-contact"
              style="top: -30px; right: 40px; display: block"
            >
              <ul v-for="i in query" :key="i._id">
                <li class="arrow right">
                  <div class="pointer">
                    <i
                      class="fa-sharp fa-solid fa-caret-up fa-lg"
                      style="color: #082b65"
                    ></i>
                  </div>
                </li>

                <li class="header dark popup-flex">
                  <div>
                    <h6>{{ i.contactPersonName[$i18n.locale] }}</h6>
                    <p>{{ i.contactPersonPosition[$i18n.locale] }}</p>
                  </div>

                  <i
                    class="fa-sharp fa-solid fa-2x fa-comment"
                    style="color: white"
                  ></i>
                </li>
                <li class="email light popup-flex">
                  <i class="fa-solid fa-mobile fa-2x" style="color: white"></i>
                  <p>{{ i.contactPersonPhone }}</p>
                </li>
                <li class="header dark popup-flex">
                  <i
                    class="fa-solid fa-envelope fa-2x"
                    style="color: white"
                  ></i>
                  <p>{{ i.contactPersonEmail }}</p>
                </li>
              </ul>
            </div>
          </Transition>
          <div
            v-for="j in i.distributionections"
            :key="j.title"
            :class="j.classes"
          >
            <router-link :to="j.link" class="read-more-link">
              <img v-lazy="j.iconImage" class="icon" alt="" />
              <h2>{{ j.title[$i18n.locale] }}</h2>
              <p>
                {{ j.text[$i18n.locale] }}
              </p>
              <span class="min-link" style="margin-top: 8px"
                ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                >{{ i.readMore[$i18n.locale] }}</span
              >
            </router-link>
          </div>
        </div>
        <div id="van-dropoff-wrapper" class="body">
          <div id="van-box-1" class="hide">
            <img src="../assets/Group1998.png" alt="distribution asset 1" />
          </div>
          <div id="van-box-2" class="hide">
            <img src="../assets/Group1999.png" alt="distribution asset 2" />
          </div>
          <div id="van-box-3" class="hide">
            <img src="../assets/Group1998.png" alt="distribution asset 3" />
          </div>
          <div id="van-box-4" class="hide">
            <img src="../assets/Group1999.png" alt="distribution asset 4" />
          </div>
          <div id="van-dropoff">
            <img src="../assets/van.png" alt="E-Shop distribution" />
          </div>
        </div>
      </div>
    </div>
    <div v-for="i in query" :key="i.title">
      <img class="responsive" v-lazy="i.mobileDistribution" />
    </div>
  </section>
</template>
