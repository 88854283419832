<script>
export default {};
</script>
<template>
  <div id="parallax-4" class="wrapper parallax lazy">
    <div class="container">
      <div class="bottom distribution lazy"></div>
      <div class="body" style="display: block">
        <div class="mask lazy">
          <div class="mask-img lazy"></div>
        </div>
        <div
          id="props-logistics-1"
          class="beyond-logistics"
          style="opacity: 0; color: #000"
        >
          Business is people
        </div>
      </div>
    </div>
  </div>
</template>
