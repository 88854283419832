<script>
import { gsap } from "gsap";
import sanity from "../client";

const heroQuery = `*[_type == "hero"] {
  title,
}`;
const staticAssets = `*[_type == "staticAssets"] {

"planeImage": planeImage.asset->url,
"cloudImage": cloudImage.asset->url, 
"person": person.asset->url,
"cityBack": cityBack.asset->url, 
"cityFront": cityFront.asset->url, 
"mountain": mountain.asset->url,
"elevator": elevator.asset->url
}`;

export default {
  name: "heroSection",
  data() {
    return {
      loading: true,
      heros: {},
      staticAssets: [],
    };
  },

  created() {
    this.fetchHero();
    this.fetchStatic();
  },

  mounted() {},
  methods: {
    fetchHero() {
      this.loading = true;
      sanity.fetch(heroQuery).then(
        (heros) => {
          this.loading = false;
          this.heros = heros;
        },
        (error) => {
          this.error = error;
        }
      );
    },
    fetchStatic() {
      this.loading = true;
      sanity.fetch(staticAssets).then(
        (staticAssets) => {
          this.loading = false;
          this.staticAssets = staticAssets;
          setTimeout(() => {
            gsap.fromTo(
              "#first-cloud-top",
              {
                left: "500px",
                opacity: 0,
              },
              {
                left: "800px",
                duration: 2,
                opacity: 1,
              }
            );
            gsap.fromTo(
              "#plane-top",
              {
                left: "800px",
              },
              {
                left: "600px",
                duration: 2,
              }
            );
          });
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>

<template>
  <section id="home" class="wrapper">
    <div id="buttons"></div>
    <div class="responsive header-text" v-for="hero in heros" :key="hero.title">
      {{ hero.title[$i18n.locale] }}
    </div>
    <img
      class="responsive"
      src="https://cdn.sanity.io/images/0mfvw1cs/production/c50d6d35f676efc2cdc6ef499087edc3f8b07294-1024x910.png"
      alt="Dimex Intro"
    />
    <div id="city-back">
      <div id="city-front">
        <div id="intro" class="container">
          <div class="body clearfix" v-for="i in staticAssets" :key="i.title">
            <div
              id="first-cloud-top"
              class="props airplane-right scale scale-7"
            >
              <img :src="i.planeImage" alt="" />
            </div>
            <div id="plane-top" class="props cloud scale scale-7">
              <img :src="i.cloudImage" alt="" />
            </div>
            <div id="intro-text" v-for="hero in heros" :key="hero.title">
              {{ hero.title[$i18n.locale] }}

              <br />
            </div>

            <img :src="i.person" id="poor-guy" alt="" />
            <div id="elevator-intro">
              <img
                :src="i.elevator"
                alt="Dimex takes over your logistics burdens!"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
