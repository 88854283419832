import { createApp } from "vue/dist/vue.esm-bundler";
import "./style.css";
import VueLazyload from "vue-lazyload";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";

const loadimage =
  "https://cdn.sanity.io/images/0mfvw1cs/production/0843d150fb9741aa38612ca4e420efa6de758e2e-217x217.png";
const errorimage = "./assets/dimex_logo.png";

createApp(App)
  .use(i18n)
  .use(VueLazyload, {
    preLoad: 1.3,
    error: errorimage,
    loading: loadimage,
    attempt: 1,
  })
  .use(router)
  .mount("#app");
