import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: "bg",
  fallbackLocale: "bg",
});

export default i18n;

export const supportedLocales = {
  en: { name: "EN" },
  bg: { name: "BG" },
};
