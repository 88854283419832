<script>
import sanity from "../client";
const query = `*[_type == "logisticsSection"] {
  _id,
mainTitle,
mainText,
contactPersonName,
contactPersonPosition,
contactPersonPhone,
contactPersonEmail,
readMore,
contactUs,
logisticSections[]{
  "iconImage": iconImage.asset->url,
  title,
  text, 
  classes, 
  link,
},
"mobileLogistics": mobileLogistics.asset->url
}`;
export default {
  name: "Logistics",
  data() {
    return {
      loading: true,
      query: [],
      show: false,
    };
  },

  created() {
    this.fetchLogistics();
  },
  methods: {
    fetchLogistics() {
      this.loading = true;
      sanity.fetch(query).then(
        (query) => {
          this.loading = false;
          this.query = query;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
    showTrue() {
      this.show = !this.show;
    },
  },
};
</script>

<template>
  <section id="logistics" class="wrapper clearfix lazy">
    <div id="logistics-background" class="clearfix lazy">
      <div class="container clearfix">
        <div id="power-line-wrapper">
          <div id="power-line-van">
            <img src="../assets/van.png" alt="Fashion Logistics" />
          </div>
        </div>
        <div class="body">
          <div
            id="logistics-box"
            class="box right popbox"
            v-for="i in query"
            :key="i._id"
          >
            <router-link
              :to="{ name: 'logisticsIntro' }"
              class="read-more-link"
              data-rel="#logistics"
            >
              <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
              <p>
                {{ i.mainText[$i18n.locale] }}
              </p>
              <span class="min-link"
                ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                >{{ i.readMore[$i18n.locale] }}</span
              >
            </router-link>
            <a @click="showTrue" class="min-link contact"
              ><i class="fa-sharp fa-solid fa-lg fa-comment fa-read-more"></i
              >{{ i.contactUs[$i18n.locale] }}</a
            >
          </div>
          <Transition>
            <div
              v-if="show"
              class="popup large"
              id="consulting-contact"
              style="top: -30px; right: 40px; display: block"
            >
              <ul v-for="i in query" :key="i._id">
                <li class="arrow right">
                  <div class="pointer">
                    <i
                      class="fa-sharp fa-solid fa-caret-up fa-lg"
                      style="color: #082b65"
                    ></i>
                  </div>
                </li>

                <li class="header dark popup-flex">
                  <div>
                    <h6>{{ i.contactPersonName[$i18n.locale] }}</h6>
                    <p>{{ i.contactPersonPosition[$i18n.locale] }}</p>
                  </div>

                  <i
                    class="fa-sharp fa-solid fa-2x fa-comment"
                    style="color: white"
                  ></i>
                </li>
                <li class="email light popup-flex">
                  <i class="fa-solid fa-mobile fa-2x" style="color: white"></i>
                  <p>{{ i.contactPersonPhone }}</p>
                </li>
                <li class="header dark popup-flex">
                  <i
                    class="fa-solid fa-envelope fa-2x"
                    style="color: white"
                  ></i>
                  <p>{{ i.contactPersonEmail }}</p>
                </li>
              </ul>
            </div>
          </Transition>
          <div v-for="i in query" :key="i.title">
            <div
              v-for="j in i.logisticSections"
              :key="j.title"
              :class="j.classes"
            >
              <a :href="j.link" class="read-more-link">
                <img v-lazy="j.iconImage" class="icon" alt="" />
                <h2>{{ j.title[$i18n.locale] }}</h2>
                <p>
                  {{ j.text[$i18n.locale] }}
                </p>
                <span class="min-link" style="margin-top: 8px"
                  ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                  >{{ i.readMore[$i18n.locale] }}</span
                >
              </a>
            </div>
          </div>
          <div id="power-line-hand" class="lazy"></div>
        </div>
      </div>
    </div>
    <div v-for="i in query" :key="i.title">
      <img class="responsive" v-lazy="i.mobileLogistics" />
    </div>
  </section>
</template>
