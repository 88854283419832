<script>
import sanity from "../client";
const query = `*[_type == "contactUs"] {
  _id,
"pdfBg": pdf.bg.asset->url,
"pdfEn": pdf.en.asset->url,
downloadText
}`;
export default {
  name: "Our Profile",
  data() {
    return {
      loading: true,
      query: [],
    };
  },

  created() {
    this.fetchOurProfile();
  },
  methods: {
    fetchOurProfile() {
      this.loading = true;
      sanity.fetch(query).then(
        (query) => {
          this.loading = false;
          this.query = query;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>
<template>
  <div id="download">
    <div id="download-container" v-for="i in query" :key="i._id">
      <a
        class="download-link"
        :href="i.pdfBg"
        target="_blank"
        v-if="$i18n.locale === 'en'"
      >
        <i class="fa-solid fa-cloud-arrow-down fa-lg" style="color: #fff"></i>
        {{ i.downloadText[$i18n.locale] }}</a
      >
      <a
        class="download-link"
        :href="i.pdfEn"
        target="_blank"
        v-if="$i18n.locale === 'bg'"
      >
        <i class="fa-solid fa-cloud-arrow-down fa-lg" style="color: #fff"></i>
        {{ i.downloadText[$i18n.locale] }}</a
      >
    </div>
  </div>
</template>
