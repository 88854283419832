<script>
export default {};
</script>

<template>
  <div id="parallax-2" class="wrapper parallax lazy">
    <div class="container">
      <div class="bottom custom lazy"></div>
      <div class="body" style="display: block">
        <div class="mask lazy">
          <div class="mask-img lazy"></div>
        </div>
        <div
          id="intro-prop-fish-1"
          class="beyond-logistics lazy"
          style="opacity: 0"
        >
          Business is people
        </div>
        <div
          id="intro-prop-fish-2"
          class="props fish-two lazy"
          style="top: 150px"
        ></div>
        <div
          id="intro-prop-fish-3"
          class="props fish-three lazy"
          style="top: 36px; left: 648px"
        ></div>
      </div>
    </div>
  </div>
</template>
