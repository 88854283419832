<script>
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import sanity from "../client";

const whoWeAreQuery = `*[_type == "whoWeAre"] {
  _id,
mainTitle,
textOne,
textTwo,
images[] {
  "image": image.asset->url,
  alt
}
}`;
export default {
  name: "WhoWeAre",
  data() {
    return {
      loading: true,
      whoWeAreQuery: [],
      settings: {
        snapAlign: "start",
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
        },

        1024: {
          itemsToShow: 2.5,
        },
        1440: {
          itemsToShow: 3.5,
        },
      },
    };
  },

  created() {
    this.fetchWhoWeAre();
  },
  methods: {
    fetchWhoWeAre() {
      this.loading = true;
      sanity.fetch(whoWeAreQuery).then(
        (whoWeAreQuery) => {
          this.loading = false;
          this.whoWeAreQuery = whoWeAreQuery;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
};
</script>

<template>
  <section id="who-we-are" class="wrapper clearfix">
    <div class="container clearfix" v-for="i in whoWeAreQuery" :key="i._id">
      <div id="who-we-are-content" class="body">
        <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
        <p>
          {{ i.textOne[$i18n.locale] }}
        </p>
        <p>
          {{ i.textTwo[$i18n.locale] }}
        </p>

        <!-- <a href="#/gallery" style="color: #fff; text-transform: uppercase">
          <span class="min-link"
            ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i>See
            More</span
          >
        </a> -->
      </div>
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        :transition="500"
        style="padding-bottom: 30px"
      >
        <Slide v-for="j in i.images" :key="j.alt">
          <div class="carousel__item">
            <img v-lazy="j.image" :alt="j.alt" />
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </section>
</template>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

/* .carousel__slide {
  padding: 10px;
} */

.carousel__prev {
  left: -20px;
  top: 100px;
}

.carousel__next {
  right: -20px;
  top: 100px;
}
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
}

@media (max-width: 1024px) {
  .carousel__item img {
    width: 300px;
  }
  .carousel__prev {
    left: 0;
    top: 100px;
  }

  .carousel__next {
    right: 0;
    top: 100px;
  }
  .carousel__pagination {
    display: none;
  }
}
</style>
