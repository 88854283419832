<script>
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import LanguageSwitcher from "./LanguageSwitcher.vue";
gsap.registerPlugin(ScrollToPlugin);

export default {
  components: { LanguageSwitcher },
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    scrollTo(id) {
      gsap.to(window, {
        duration: 1.5,
        scrollTo: id,
        ease: "power4.inOut",
      });
      this.showMobileMenu = false;
    },
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
};
</script>

<template>
  <header class="menu">
    <div class="nav-menu" v-if="$i18n.locale === 'en'">
      <a @click="scrollTo('#home')" class="mobile-logo"
        ><img
          src="https://cdn.sanity.io/images/0mfvw1cs/production/3bd29ef17614d9a7ae7545249129ddb51d1bb7da-201x78.png"
          alt=""
          style="width: 100px"
      /></a>
      <i
        class="fas fa-bars hamburger fa-xl"
        @click="showMenu()"
        v-if="!this.showMobileMenu"
      >
      </i>
      <i
        class="fa-solid fa-xmark hamburger fa-xl"
        @click="showMenu()"
        v-if="this.showMobileMenu"
      >
      </i>
      <div
        class="nav-content"
        :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
      >
        <a @click="scrollTo('#home')" class="desktop-logo"
          ><img
            src="https://cdn.sanity.io/images/0mfvw1cs/production/3bd29ef17614d9a7ae7545249129ddb51d1bb7da-201x78.png"
            alt=""
            style="width: 100px"
        /></a>
        <ul class="nav-items menu-inner-wrapper">
          <li>
            <a @click="scrollTo('#intro-prop-consulting-1')">Consulting</a>
          </li>
          <li><a @click="scrollTo('.road-transport')">Transport</a></li>
          <li><a @click="scrollTo('#customs-background')">Customs</a></li>
          <li>
            <a @click="scrollTo('#logistics .body')">Logistics</a>
          </li>
          <li>
            <a @click="scrollTo('#props-distribution-2')">Distribution</a>
          </li>
          <li><a @click="scrollTo('#who-we-are')">Who we are</a></li>
          <li><a @click="scrollTo('#our-team')">About us</a></li>
          <li><a @click="scrollTo('#get-in-touch')">Contact Us</a></li>
        </ul>

        <language-switcher />
      </div>
    </div>
    <div class="nav-menu" v-if="$i18n.locale === 'bg'">
      <a @click="scrollTo('#home')" class="mobile-logo"
        ><img
          src="https://cdn.sanity.io/images/0mfvw1cs/production/3bd29ef17614d9a7ae7545249129ddb51d1bb7da-201x78.png"
          alt=""
          style="width: 100px"
      /></a>
      <i
        class="fas fa-bars hamburger fa-xl"
        @click="showMenu()"
        v-if="!this.showMobileMenu"
      >
      </i>
      <i
        class="fa-solid fa-xmark hamburger fa-xl"
        @click="showMenu()"
        v-if="this.showMobileMenu"
      >
      </i>
      <div
        class="nav-content"
        :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
      >
        <a @click="scrollTo('#home')" class="desktop-logo"
          ><img
            src="https://cdn.sanity.io/images/0mfvw1cs/production/3bd29ef17614d9a7ae7545249129ddb51d1bb7da-201x78.png"
            alt=""
            style="width: 100px"
        /></a>
        <ul class="nav-items menu-inner-wrapper">
          <li>
            <a @click="scrollTo('#intro-prop-consulting-1')">Консултации</a>
          </li>
          <li><a @click="scrollTo('.road-transport')">Транспорт</a></li>
          <li><a @click="scrollTo('#customs-background')">Митници</a></li>
          <li>
            <a @click="scrollTo('#logistics .body')">Логистика</a>
          </li>
          <li><a @click="scrollTo('#props-distribution-2')">Дистрибуция</a></li>
          <li><a @click="scrollTo('#who-we-are')">Кои сме ние</a></li>
          <li><a @click="scrollTo('#our-team')">За нас</a></li>
          <li><a @click="scrollTo('#get-in-touch')">Контакти</a></li>
        </ul>

        <language-switcher />
      </div>
    </div>
  </header>
</template>

<style>
.nav-menu {
  position: fixed;
  width: 100%;
}
.nav-menu,
.nav-menu-inner {
  background-color: white;
  z-index: 999;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  align-items: center;
}
.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-items li {
  padding: 0 10px;
}
i.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger-inner {
  display: none;
  cursor: pointer;
}
.mobile-logo {
  display: none;
}

@media (min-width: 1160px) and (max-width: 1260px) {
  .menu-inner-wrapper li a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1160px) {
  .menu {
    margin-bottom: 50px;
  }
  .desktop-logo {
    display: none;
  }
  .mobile-logo {
    display: block;
  }
  .nav-menu {
    padding: 10px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .nav-menu-inner {
    padding: 20px;
    position: absolute;
    top: 20px;
    left: 10px;
    width: 85%;
    border-radius: 10px;
    transition: all 0.2s ease-out;
  }
  .nav-menu-inner.sticky {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0px;
    transition: all 0.2s ease-out;
  }
  .nav-menu-inner.sticky i.hamburger-inner {
    right: 50px;
    transform-origin: top left;
  }
  .open-menu {
    opacity: 1;
    height: 100%;
    visibility: visible;
  }
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
    visibility: hidden;
  }
  .nav-content {
    flex-direction: column;
    z-index: 100;
    position: relative;
    transition: all 0.2s ease-out;
  }
  .nav-items {
    flex-direction: column;
    margin-bottom: 20px;
  }
  i.hamburger {
    display: block;
    position: absolute;
    top: 30px;
    right: 40px;
  }
  i.hamburger-inner {
    display: block;
    position: absolute;
    top: 20px;
    right: 30px;
  }
}
</style>
