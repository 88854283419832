<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import sanity from "../client";

import GoogleMaps from "../components/GoogleMaps";

const ourTeamQuery = `*[_type == "ourTeamSection"] {
  _id,
  mainTitle,
  mainText,
  teamMembers[] {
    contactPersonName,
    contactPersonPosition,
    contactPersonPhone,
    email,
    "profileImage": profileImage.asset->url,
    
  }
}`;
export default {
  name: "ourTeamSection",
  data() {
    return {
      loading: true,
      ourTeamQuery: [],
      settings: {
        snapAlign: "start",
      },
      breakpoints: {
        0: {
          itemsToShow: 1,
        },
        700: {
          itemsToShow: 3,
        },

        1024: {
          itemsToShow: 4,
        },
      },
    };
  },

  created() {
    this.fetchOurTeamQuery();
  },
  methods: {
    fetchOurTeamQuery() {
      this.loading = true;
      sanity.fetch(ourTeamQuery).then(
        (ourTeamQuery) => {
          this.loading = false;
          this.ourTeamQuery = ourTeamQuery;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },

  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    GoogleMaps,
  },
};
</script>
<template>
  <section id="our-team" class="wrapper clearfix">
    <GoogleMaps />
    <div id="team" class="container lazy">
      <div class="body" v-for="i in ourTeamQuery" :key="i._id">
        <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
        <Carousel
          :settings="settings"
          :breakpoints="breakpoints"
          :transition="500"
          style="padding-bottom: 30px"
        >
          <Slide v-for="j in i.teamMembers" :key="j.contactPersonName">
            <div class="carousel__item__team">
              <img v-lazy="j.profileImage" :alt="j.contactPersonName" />
              <h3>{{ j.contactPersonName[$i18n.locale] }}</h3>
              <p>{{ j.contactPersonPosition[$i18n.locale] }}</p>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>

        <div id="about-us">
          <p style="color: white; font-size: 16px">
            {{ i.mainText[$i18n.locale] }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.carousel__item__team {
  min-height: 200px;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.carousel__item__team h3 {
  color: #fff;
  margin-top: 16px;
  font-size: 1.4rem;
  font-weight: 600;
}

.carousel__item__team p {
  color: #fff;
  margin-top: 8px;
}

.carousel__item__team img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .carousel__item__team img {
    width: 200px;
    height: 200px;
  }
}
</style>
