<template>
  <div id="parallax-3" class="wrapper parallax lazy">
    <div class="container">
      <div class="bottom logistics lazy"></div>
      <div class="body" style="display: block">
        <div class="mask lazy">
          <div class="mask-img lazy"></div>
        </div>
        <div id="props-customs-3" class="beyond-logistics" style="opacity: 0">
          Business is people
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
