<script>
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Hero from "../components/Hero";
import Consulting from "../components/Consulting";
import Transport from "../components/Transport";
import Customs from "../components/Customs";
import ParallaxOne from "../components/ParallaxOne";
import ParallaxTwo from "../components/ParallaxTwo";
import Logistics from "../components/Logistics";
import Distribution from "../components/Distribution";
import WhoWeAre from "../components/WhoWeAre";
import ParallaxThree from "../components/ParallaxThree";
import ParallaxFour from "../components/ParallaxFour";
import CaseStudies from "../components/CaseStudies";
import Facts from "../components/Facts";
import OurTeam from "../components/OurTeam";
import ContactUs from "../components/ContactUs";
import Download from "../components/Download";
import Header from "../components/Header.vue";
import ToTop from "../components/ToTop.vue";

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      lazyloadThrottleTimeout: null,
    };
  },

  created() {
    this.lazyLoadingImagesCustom();
    this.activeLocale();
    // gsap.to(window, {
    //   duration: 1.5,
    //   scrollTo: 0,
    //   ease: "power4.inOut",
    // });
  },
  computed: {
    console: () => console,
  },

  mounted() {
    document.addEventListener("backbutton", this.yourCallBackFunction, false);
    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
      var mql = window.matchMedia(mediaQuery);
      mql.addListener(function (e) {
        return layoutChangedCallback(e.matches);
      });
      layoutChangedCallback(mql.matches);
    }
    installMediaQueryWatcher("(min-width: 1024px)", function (matches) {
      if (matches) {
        ScrollTrigger.create({
          start: 0,
          end: "max",
          onUpdate: updateValues,
        });
        var tl = gsap.timeline({ repeat: -1 });
        tl.to("#city-back", {
          backgroundPosition: "9999px 100%",
          duration: 999,
        }).to(
          "#city-front",
          {
            backgroundPosition: "8500px 100%",
            duration: 999,
          },
          "<"
        );

        // eslint-disable-next-line no-inner-declarations
        function updateValues() {
          if (
            ScrollTrigger.isInViewport(document.querySelector("#city-back"))
          ) {
            tl.play();
          } else {
            tl.pause();
          }
        }
        updateValues();

        let truckTl = gsap.timeline({
          scrollTrigger: {
            trigger: "#truck-wrapper",
            start: "top 350px",

            end: "bottom bottom",
            toggleActions: "play play none reverse",
            fastScrollEnd: 5000,
          },
        });

        truckTl
          .set("#box", {
            onUpdate: function () {
              document.querySelector("#box").classList.add("stickit");
            },
          })
          .set(
            "#box",
            {
              onUpdate: function () {
                document.querySelector("#box").classList.remove("stickit");
              },
            },
            0.01
          )
          .set("#truck", {
            onUpdate: function () {
              document.querySelector("#truck").classList.remove("full");
            },
          })
          .set(
            "#truck",
            {
              onUpdate: function () {
                document.querySelector("#truck").classList.add("full");
              },
            },
            "<"
          )
          .to("#truck", {
            x: 600,
            duration: 2,
          }),
          "+=1";

        let boxStick = gsap.timeline({
          scrollTrigger: {
            trigger: "#city-back",
            start: "150px #city-back ",
            end: "150px #city-back",
            fastScrollEnd: 5000,
            toggleActions: "play play none reverse",
            invalidateOnRefresh: "false",

            onEnterBack: () => {
              document.querySelector("#box").classList.remove("stickit");
            },
          },
        });
        boxStick

          .set(
            "#box",
            {
              onComplete: function () {
                document.querySelector("#box").classList.remove("stickit");
              },
            },
            0
          )
          .set(
            "#box",
            {
              onComplete: function () {
                document.querySelector("#box").classList.add("stickit");
              },
            },
            0.01
          );

        let TransportChainTl = gsap.timeline({
          scrollTrigger: {
            trigger: "#transport",
            start: "top center ",
            end: "bottom center",
            toggleActions: "restart play none reverse",
          },
        });
        TransportChainTl.fromTo(
          "#intro-prop-transport-1",
          {
            left: 0,
            opacity: 0,
          },
          {
            left: 250,
            opacity: 1,
            duration: 2,
          }
        )
          .to(
            "#intro-prop-transport-2",
            {
              x: -100,
              duration: 1,
              opacity: 1,
            },
            "<"
          )
          .to(
            "#intro-prop-transport-3",
            {
              x: -50,
              duration: 1,
              opacity: 1,
            },
            "<"
          );

        let parallax2Tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#parallax-2",
            start: "top center",
            end: "bottom center",
            toggleActions: "restart play none reverse",
          },
        });
        parallax2Tl.to("#intro-prop-fish-1", {
          opacity: 1,
          duration: 1.5,
        });
        parallax2Tl.fromTo(
          "#intro-prop-fish-2",
          {
            left: -50,
          },
          {
            left: 30,
            duration: 1,
          },
          "<0.5"
        );
        parallax2Tl.to(
          "#intro-prop-fish-3",
          {
            left: 450,
            duration: 1.5,
          },
          "<"
        );
        let parallax3Tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#parallax-3",
            start: "top center",
            end: "bottom center",
            toggleActions: "restart play none reverse",
          },
        });
        parallax3Tl.to("#props-customs-3", {
          opacity: 1,
          duration: 1.5,
        });
        let customsTl = gsap.timeline({
          scrollTrigger: {
            trigger: "#customs",
            start: "top center",
            end: "bottom center",
            toggleActions: "play play none reverse",
          },
        });

        customsTl
          .fromTo(
            "#transit-truck",
            {
              right: "80%",
            },
            {
              right: "46%",
              duration: 2,
            }
          )
          .to(
            "#transit-clark",
            {
              left: "50%",
              duration: 1,
            },
            ">-0.5"
          )
          .set("#transit-clark", { className: "" })
          .set("#transit-truck", { className: "" })
          .set("#transit-clark", { className: "full" })
          .to(
            "#transit-clark",
            {
              left: "60%",
              duration: 1,
            },
            ">"
          );

        let parallax4Tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#parallax-4",
            start: "top center",
            end: "bottom center",
            toggleActions: "restart play none reverse",
          },
        });
        parallax4Tl.to("#props-logistics-1", {
          opacity: 1,
          duration: 1.5,
        });
        let logisticsTl = gsap.timeline({
          scrollTrigger: {
            trigger: "#logistics",
            start: "top center",
            end: "bottom center",
            toggleActions: "play play none reverse",
          },
        });
        logisticsTl
          .set("#power-line-hand", { className: "full" })
          .to(
            "#power-line-van",
            {
              right: "10%",
              duration: 1,
            },
            ">"
          )

          .to(
            "#power-line-hand",
            {
              left: "60%",
              duration: 1.5,
            },
            "+0.5"
          )
          .set("#power-line-hand", { className: "" })
          .to(
            "#power-line-van",
            {
              right: "0%",
              duration: 1,
            },
            ">"
          );
        let distributionTl = gsap.timeline({
          scrollTrigger: {
            trigger: "#distribution",
            start: " top 100px",

            end: "bottom center",
            toggleActions: "restart play none reverse",
          },
        });
        distributionTl
          .to("#van-dropoff", {
            left: "10%",
            duration: 1.5,
            ease: "power4.out",
          })
          .set("#van-box-1", { className: "" }, ">")
          .to("#van-dropoff", {
            left: "50%",
            duration: 2,
            ease: "power4.out",
          })
          .set("#van-box-2", { className: "" }, ">")
          .to(
            "#van-dropoff",
            {
              left: "80%",
              duration: 1.5,
              ease: "power4.out",
            },
            ">"
          )
          .set("#van-box-3", { className: "" }, ">")
          .to(
            "#van-dropoff",
            {
              left: "120%",
              duration: 2,
              ease: "power4.out",
            },
            ">"
          )
          .set("#van-box-4", { className: "" }, ">")
          .to(
            "#van-dropoff",
            {
              left: "160%",
              duration: 2,
              ease: "power4.out",
            },
            ">"
          );
      }
    });
  },
  methods: {
    yourCallBackFunction() {
      // document.querySelector("#box").classList.remove("stickit");
      console.log("test");
    },
    lazyLoadingImagesCustom() {
      setTimeout(() => {
        var lazyloadImages;

        if ("IntersectionObserver" in window) {
          lazyloadImages = document.querySelectorAll(".lazy");

          var imageObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
              if (entry.isIntersecting) {
                var image = entry.target;
                image.classList.remove("lazy");
                imageObserver.unobserve(image);
              }
            });
          });

          lazyloadImages.forEach(function (image) {
            imageObserver.observe(image);
          });
        } else {
          document.addEventListener("scroll", this.lazyload);
          window.addEventListener("resize", this.lazyload);
          window.addEventListener("orientationChange", this.lazyload);
        }
      });
    },
    lazyload() {
      var lazyloadImages;
      lazyloadImages = document.querySelectorAll(".lazy");
      if (this.lazyloadThrottleTimeout) {
        clearTimeout(this.lazyloadThrottleTimeout);
      }

      this.lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < window.innerHeight + scrollTop) {
            img.src = img.dataset.src;
            img.classList.remove("lazy");
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener("scroll", this.lazyload);
          window.removeEventListener("resize", this.lazyload);
          window.removeEventListener("orientationChange", this.lazyload);
        }
      }, 20);
    },
    activeLocale() {
      return this.$i18n.locale;
    },
  },

  components: {
    Hero,
    Consulting,
    Transport,
    Customs,
    ParallaxOne,
    ParallaxTwo,
    Logistics,
    Distribution,
    WhoWeAre,
    ParallaxThree,
    ParallaxFour,
    CaseStudies,
    Facts,
    OurTeam,
    ContactUs,
    Download,
    Header,
    ToTop,
  },
};
</script>

<template>
  <Header />
  <ToTop />
  <div id="wrapper" class="clearfix">
    <div id="box"></div>

    <Hero />

    <Consulting />

    <ParallaxOne />

    <Transport />

    <ParallaxTwo />

    <Customs />

    <ParallaxThree />

    <Logistics />

    <ParallaxFour />

    <Distribution />

    <WhoWeAre />

    <CaseStudies />

    <Facts />

    <OurTeam />

    <ContactUs />

    <Download />
  </div>
</template>
