<script>
import sanity from "../client";
const query = `*[_type == "customsSection"] {
  _id,
mainTitle,
mainText,
contactPersonName,
contactPersonPosition,
contactPersonPhone,
contactPersonEmail,
readMore,
contactUs,
"firstIconImage": firstIconImage.asset->url,
firstBoxTitle,
firstBoxText,
"secondIconImage": secondIconImage.asset->url,
secondBoxTitle,
secondBoxText,
"mobileCustoms": mobileCustoms.asset->url
}`;
export default {
  name: "Customs",
  data() {
    return {
      loading: true,

      query: [],
      show: false,
    };
  },

  created() {
    this.fetchConsulting();
  },
  methods: {
    fetchConsulting() {
      this.loading = true;
      sanity.fetch(query).then(
        (query) => {
          this.loading = false;
          this.query = query;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
    showTrue() {
      this.show = !this.show;
    },
  },
};
</script>
<template>
  <section id="customs" class="wrapper clearfix lazy">
    <div id="customs-bottom-line" class="clearfix lazy">
      <div id="customs-background" class="clearfix lazy">
        <div class="container clearfix" style="max-width: 1240px">
          <div class="body" v-for="i in query" :key="i._id">
            <div id="customs-box" class="box right popbox">
              <h2>{{ i.mainTitle[$i18n.locale] }}</h2>
              <p>
                {{ i.mainText[$i18n.locale] }}
              </p>
              <a @click="showTrue" class="min-link contact"
                ><i class="fa-sharp fa-solid fa-lg fa-comment fa-read-more"></i
                >{{ i.contactUs[$i18n.locale] }}</a
              >
            </div>
            <div class="box left customs-wharehouse" style="">
              <router-link :to="{ name: 'customs' }" class="read-more-link">
                <img v-lazy="i.firstIconImage" class="icon" alt="" />
                <h2>{{ i.firstBoxTitle[$i18n.locale] }}</h2>
                <p>
                  {{ i.firstBoxText[$i18n.locale] }}
                </p>
                <span class="min-link" style="margin-top: 8px"
                  ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                  >{{ i.readMore[$i18n.locale] }}</span
                >
              </router-link>
            </div>
            <div class="box left customs-transit" style="">
              <router-link :to="{ name: 'customs' }" class="read-more-link">
                <img v-lazy="i.secondIconImage" class="icon" alt="" />
                <h2>{{ i.secondBoxTitle[$i18n.locale] }}</h2>
                <p>
                  {{ i.secondBoxText[$i18n.locale] }}
                </p>
                <span class="min-link" style="margin-top: 8px"
                  ><i class="fa-solid fa-circle-plus fa-lg fa-read-more"></i
                  >{{ i.readMore[$i18n.locale] }}</span
                >
              </router-link>
            </div>
            <Transition>
              <div
                v-if="show"
                class="popup large"
                id="consulting-contact"
                style="top: -30px; right: 40px; display: block"
              >
                <ul v-for="i in query" :key="i._id">
                  <li class="arrow right">
                    <div class="pointer">
                      <i
                        class="fa-sharp fa-solid fa-caret-up fa-lg"
                        style="color: #082b65"
                      ></i>
                    </div>
                  </li>

                  <li class="header dark popup-flex">
                    <div>
                      <h6>{{ i.contactPersonName[$i18n.locale] }}</h6>
                      <p>{{ i.contactPersonPosition[$i18n.locale] }}</p>
                    </div>

                    <i
                      class="fa-sharp fa-solid fa-2x fa-comment"
                      style="color: white"
                    ></i>
                  </li>
                  <li class="email light popup-flex">
                    <i
                      class="fa-solid fa-mobile fa-2x"
                      style="color: white"
                    ></i>
                    <p>{{ i.contactPersonPhone }}</p>
                  </li>
                  <li class="header dark popup-flex">
                    <i
                      class="fa-solid fa-envelope fa-2x"
                      style="color: white"
                    ></i>
                    <p>{{ i.contactPersonEmail }}</p>
                  </li>
                </ul>
              </div>
            </Transition>
          </div>
          <div id="transit-clark" class="lazy"></div>
          <div id="transit-truck" class="full lazy"></div>

          <div
            id="prop-customs-1"
            class="props cloud scale scale-8 lazy"
            style="top: -162px; left: 669px"
          ></div>
          <div
            id="prop-customs-2"
            class="props cloud scale scale-4 lazy"
            style="top: 245px; left: 226px"
          ></div>
        </div>
      </div>
    </div>
    <div v-for="i in query" :key="i.title">
      <img class="responsive" v-lazy="i.mobileCustoms" />
    </div>
  </section>
</template>
