<script>
import sanity from "../client";
const caseStudiesQuery = `*[_type == "caseStudiesSection"] {
  _id,
  caseStudiesTitle,
  caseStudiesBoxes[] {
caseStudyName,
"pdfBg": pdf.bg.asset->url,
"pdfEn": pdf.en.asset->url
  }
}`;
export default {
  name: "caseStudies",
  data() {
    return {
      loading: true,
      caseStudiesQuery: [],
    };
  },

  created() {
    this.fetchCaseStudies();
  },
  methods: {
    fetchCaseStudies() {
      this.loading = true;
      sanity.fetch(caseStudiesQuery).then(
        (test) => {
          this.loading = false;
          this.caseStudiesQuery = test;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>
<template>
  <section id="case-studies" class="wrapper clearfix lazy">
    <div class="container clearfix">
      <div
        class="body clearfix"
        v-lazy-container="{ selector: 'img' }"
        v-for="i in caseStudiesQuery"
        :key="i._id"
      >
        <h2>{{ i.caseStudiesTitle[$i18n.locale] }}</h2>

        <ul class="studies clearfix">
          <li v-for="j in i.caseStudiesBoxes" :key="j.pdf">
            <a
              :href="j.pdfEn"
              target="_blank"
              class="case-studies-link"
              v-if="$i18n.locale === 'en'"
            >
              <img
                data-src="https://cdn.sanity.io/images/0mfvw1cs/production/0843d150fb9741aa38612ca4e420efa6de758e2e-217x217.png"
                style="width: 125px; height: auto"
                alt="Case Studies Logo"
              />
              <p>
                {{ j.caseStudyName.en }}
              </p>
            </a>
            <a
              :href="j.pdfBg"
              target="_blank"
              class="case-studies-link"
              v-if="$i18n.locale === 'bg'"
            >
              <img
                data-src="https://cdn.sanity.io/images/0mfvw1cs/production/0843d150fb9741aa38612ca4e420efa6de758e2e-217x217.png"
                style="width: 125px; height: auto"
                alt="Case Studies Logo"
              />
              <p>
                {{ j.caseStudyName.bg }}
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
