<template>
  <GoogleMap
    api-key="AIzaSyDQwitYE_fkqzOC6327Ku9_9HMsBujhRDo "
    style="width: 100%; height: 1400px"
    :center="center"
    :zoom="16"
  >
    <Marker :options="markerOptions" />
  </GoogleMap>
</template>

<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },
  setup() {
    const marker = { lat: 42.67975545078922, lng: 23.392912578921763 };
    const center = { lat: 42.6826411683143, lng: 23.393214718514574 };
    const markerOptions = {
      position: marker,
      title: "Main Warehouse",
    };

    return { center, markerOptions };
  },
});
</script>
