<template>
  <div id="parallax-1" class="wrapper parallax lazy">
    <div class="container">
      <div class="bottom transport lazy"></div>
      <div class="body clearfix" style="display: block">
        <div class="mask clearfix lazy">
          <div class="mask-img lazy"></div>
        </div>
      </div>
    </div>
  </div>
</template>
