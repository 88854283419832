<script>
import sanity from "../client";
const infrastructureFactsQuery = `*[_type == "infraFacts"] {
  _id,
  infraTitle,
  infraFactsList[]{
text,
"iconImage": iconImage.asset->url
  }
}`;
export default {
  name: "infrastructureFacts",
  data() {
    return {
      loading: true,
      infrastructureFactsQuery: [],
    };
  },

  created() {
    this.fetchInfrastructureFacts();
  },
  methods: {
    fetchInfrastructureFacts() {
      this.loading = true;
      sanity.fetch(infrastructureFactsQuery).then(
        (infrastructureFactsQuery) => {
          this.loading = false;
          this.infrastructureFactsQuery = infrastructureFactsQuery;
          setTimeout(() => {});
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },
};
</script>
<template>
  <section id="installations" class="wrapper clearfix lazy">
    <div class="container">
      <div
        class="body clearfix"
        v-for="i in infrastructureFactsQuery"
        :key="i._id"
      >
        <h2>{{ i.infraTitle[$i18n.locale] }}</h2>
        <div class="installation-icons">
          <ul class="icon-list sprite-3 en">
            <li class="clearfix" v-for="j in i.infraFactsList" :key="j.id">
              <img
                v-lazy="j.iconImage"
                alt="Icon image for an infrastructure Fact"
                class="icon"
              />

              <p>
                {{ j.text[$i18n.locale] }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
